import React, { useContext, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import CtaBtn from "./CtaBtn"
import StyledInput from "./StyledInput"

import { ModalContext } from "./layout"

import { formSetup } from "../form"
import emailjs from "@emailjs/browser"

export const FormFieldsContext = React.createContext(null)

const FormModal = ({ modalDate }) => {
  let initialFields = {}
  let initialFieldsErrors = {}

  formSetup.forEach(item => {
    const { id, value, error } = item

    if (modalDate && id === "date") {
      initialFields = { ...initialFields, date: modalDate.date }
    } else {
      initialFields = { ...initialFields, [id]: value }
    }

    initialFieldsErrors = {
      ...initialFieldsErrors,
      [id]: error,
    }
  })

  const { closeFormModal, formVisible, data } = useContext(ModalContext)
  const [formValues, setFormValues] = useState(initialFields)
  const [formErrors, setFormErrors] = useState(initialFieldsErrors)
  const [formStep, setFormStep] = useState(0)

  const renderFields = formSetup.map(item => (
    <StyledInput
      key={item.id}
      {...item}
      provider={FormFieldsContext}
      date={item.id === "date" && modalDate && modalDate.date}
    />
  ))

  const setValue = (id, value) => {
    return setFormValues({ ...formValues, [id]: value })
  }

  const setError = (id, value) => {
    return setFormErrors({ ...formErrors, [id]: value })
  }

  const sendMessage = form => {
    setFormStep(1)
    setFormValues(initialFields)
    setFormErrors(initialFieldsErrors)

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE,
        process.env.GATSBY_EMAILJS_APPLICATION_TEMPLATE,
        form,
        process.env.GATSBY_EMAILJS_ID
      )
      .then(res => setFormStep(2))
      .catch(err => setFormStep(3))
  }

  const submitForm = e => {
    e.preventDefault()
    const isValid = Object.keys(formErrors).every(key => formErrors[key] === "")

    if (!isValid) return

    return sendMessage(e.target)
  }

  const renderStep = () => {
    switch (formStep) {
      case 0:
        return (
          <>
            <h3 className="form-modal__title">
              Zapisz się na kurs nauki jazdy
            </h3>
            <form
              onSubmit={submitForm}
              className="form-modal__form d-flex flex-wrap justify-content-between"
            >
              {renderFields}
              <div className="d-flex justify-content-end col-12">
                <CtaBtn
                  type="submit"
                  text="Wyślij zgłoszenie"
                  action={null}
                  _classes="cta-btn--dark"
                />
              </div>
            </form>
          </>
        )
      case 1:
        return (
          <>
            <h3 className="form-modal__title">Trwa wysyłanie zgłoszenia</h3>
            <div className="form-modal__content col-12">
              <div className="loading"></div>
            </div>
          </>
        )
      case 2:
        return (
          <>
            <h3 className="form-modal__title">Zgłoszenie zostało wysłane</h3>
            <div className="form-modal__content col-12">
              <p className="styled-section__text">
                Odpowiedź otrzymasz na podany adres e-mail, bądź skontaktujemy
                się z Tobą telefonicznie. Staramy się odpowiadać na Wasze
                zgłoszenia jak najszybciej jest to możliwe.
              </p>
            </div>
          </>
        )
      case 3:
        return (
          <>
            <h3 className="form-modal__title">
              Zgłoszenie napotkało problem :(
            </h3>
            <div className="form-modal__content col-12">
              <p className="styled-section__text">
                Natknęliśmy się na problem w komunikacji z serwerem, prosimy o
                zgłoszenie zaistniałego problemu.
              </p>
            </div>
          </>
        )
      default:
        return (
          <>
            <h3 className="form-modal__title">
              Podczas próby wysłania zgłoszenia napotkaliśmy nieznany problem :(
            </h3>
            <div className="form-modal__content col-12">
              <p className="styled-section__text">
                Natknęliśmy się na nieznany problem, prosimy o zgłoszenie
                zaistniałego problemu.
              </p>
            </div>
          </>
        )
    }
  }

  return (
    <FormFieldsContext.Provider
      value={{
        values: formValues,
        errors: formErrors,
        setValue,
        setError,
        data,
      }}
    >
      <div
        className={`modal d-flex align-items-center justify-content-center form-modal${
          formVisible ? " modal--visible" : ""
        }`}
      >
        <div className="modal__container form-modal__container d-flex">
          <div className="form-modal__image">
            <StaticImage
              src="../images/driving-school.jpg"
              alt="nauka jazdy"
              className="form-modal__picture"
            />
          </div>
          <div className="modal__content col-12">
            <div className="d-flex justify-content-end">
              <button
                className="modal__btn-close"
                onClick={closeFormModal}
                aria-label="Zamknięcie modala"
              >
                <div className="btn-close__inner column align-items-center justify-content-center"></div>
              </button>
            </div>
            {renderStep()}
          </div>
        </div>
      </div>
    </FormFieldsContext.Provider>
  )
}

export default FormModal
