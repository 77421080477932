import React, { useState, useContext, useEffect } from "react"

import { IoAlertCircle } from "@react-icons/all-files/io5/IoAlertCircle"

import { validation } from "../form"

const StyledInput = ({
  id,
  name,
  type,
  label,
  required,
  _classes,
  provider,
  date,
}) => {
  const { values, errors, setValue, setError, data } = useContext(provider)

  if (!id) throw new Error("Unset required property id!")
  if (!type) throw new Error("Unset required property type!")
  if (!name) throw new Error("Unset required property name!")
  if (!label) throw new Error("Unset required property label!")

  const [touched, setTouched] = useState(false)

  const inputValue = values[id]

  const fieldClasses = `styled-input${" " + _classes}${
    errors[id] !== "" && errors[id] !== "Untouched"
      ? " styled-input--danger"
      : ""
  }`

  const onChange = e => {
    setValue(id, e.target.value)
    validation(id, e.target.value, touched, required, setError)
  }

  const onBlur = () => {
    setTouched(true)
    validation(id, inputValue, true, required, setError)
  }
  
  const dates = data
  ? data.registrations.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).filter(({ date, isFull }) => new Date(date) >= new Date() && !isFull)
  : []
  
  useEffect(() => {
    if (!date) return
    setValue("date", date)
    validation("date", date, true, required, setError)
  }, [date])

  const generateInput = () => {
    if (type === "select" && id === "date")
      return (
        <select
          className="styled-input__field"
          id={id}
          name={name}
          value={inputValue}
          onChange={onChange}
          onClick={onBlur}
          required={required}
        >
          <option value={""} disabled defaultValue>
            Wybierz datę
          </option>
          {dates.map(item => (
            <option key={item.id} value={item.date} disabled={item.isFull}>
              {item.date} {item.postscript}
            </option>
          ))}
        </select>
      )

    if (type === "select" && id === "category" && data)
      return (
        <select
          className="styled-input__field"
          id={id}
          name={name}
          value={inputValue}
          onChange={onChange}
          onClick={onBlur}
          required={required}
        >
          <option value={""} disabled defaultValue>
            Wybierz
          </option>
          {data?.offers
            ?.sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
            .map(item => {
              if (
                !item ||
                item.subtitle === "Badania lekarskie" ||
                !!!item.subtitle ||
                item.subtitle === "Dodatkowe jazdy"
              )
                return null
              return (
                <option key={item.id} value={item.subtitle}>
                  {item.subtitle}
                </option>
              )
            })}
        </select>
      )

    if (type === "textarea")
      return (
        <textarea
          className="styled-input__field styled-input__textarea"
          id={id}
          name={name}
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
        />
      )

    return (
      <input
        className="styled-input__field"
        id={id}
        type={type}
        name={name}
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
    )
  }

  return (
    <div className={fieldClasses}>
      {generateInput()}
      <label className="styled-input__label" htmlFor={id}>
        {label}
      </label>
      <div className="styled-input__warring">
        <IoAlertCircle />
      </div>
      <p className="warring__info">{errors[id]}</p>
    </div>
  )
}

export default StyledInput
